<template>
    <v-dialog v-model="dialog" width="1200">
        <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                Click Me
            </v-btn> -->
            <a v-bind="attrs" v-on="on">
                Cuentas Bancarias
            </a>
        </template>

        <v-card class="px-5 pt-5 pb-5">
            <v-card-title class="justify-left d-flex flex-row pb-2 align-start">
                <h2 class="text-uppercase">Cuentas Bancarias</h2>
                <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

            </v-card-title>

            <v-card-text v-for="(item, index) in bank_accounts" :key="index">
                <v-container fluid>
                    <v-row>
                        <v-col col="2" md="2">
                            <v-img :src="item.bank_type.photo_url" :alt="item.bank_type.name"></v-img>
                        </v-col>
                        <v-col col="10" md="10">
                            <p> Titular : {{ item.account_holder_name }}</p>
                            <p> N°CUENTA : {{ item.account_number }}</p>
                            <p> N°CCI : {{ item.interbank_code }}</p>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
          
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'BankAccountModal',
    props: ['bank_accounts'],
    data: () => ({
        dialog: false
    }),
    created(){
        console.log(this.bank_accounts);
    }
}
</script>

<style>
</style>