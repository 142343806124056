<template>
  <v-row align="center" class="list">
    <v-col cols="12" sm="12">
      <v-data-table :mobile-breakpoint="0" class="iconTable" :headers="headers" :items="items.data" disable-pagination
        :hide-default-footer="true" :loading="fetchingData" :loading-text="'Cargando... Espere por favor'"
        :no-data-text="'datos no disponibles'" item-key="_id" hide-default-header>
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header" style="background-color: #299F7E;">
            <tr>
              <template v-for="header in headers">
                <th :key="header.value" :class="header.align">
                  <v-row>
                    <v-col cols="12">
                      <h2 style="color: white">
                        {{ header.text }}
                      </h2>
                    </v-col>
                  </v-row>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #[`item.puntos`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.position }}
          </td>
        </template>
        <template #[`item.alias`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.user.alias ? item.user.alias : '' }}
          </td>
        </template>
        <template #[`item.user`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.user.full_name ? item.user.full_name : '' }}
          </td>
        </template>
        <template #[`item.dni`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.user.document_number ? item.user.document_number : '' }}
          </td>
        </template>
        <template #[`item.polla`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.polla.name ? item.polla.name : '' }}
          </td>
        </template>
        <template #[`item.estado`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.status_text }}
          </td>
        </template>
        <template #[`item.cuentas`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            <BankAccountModal :bank_accounts="item.user.bank_accounts"> </BankAccountModal>
          </td>
        </template>

        <template #[`item.pago`]="{ item }">
          <td :style="
            items.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          " style="padding-top:10px; width: 300px;">

            <div class="d-inline-flex">

              <InputPhotoUpload :item="item" :parentCallback="imageUploadEvent" style="width: 300px;">
              </InputPhotoUpload>
              <ImageModal :item="item"></ImageModal>
             
            </div>
          </td>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex justify-end">
          <v-pagination v-model="page" :length="items.last_page" total-visible="7" next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left" @input="handlePageChange" circle></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BankAccountModal from "../modals/BankAccountModal.vue";
import ImageModal from "../modals/ImageModal.vue";
import InputPhotoUpload from "@/components/util/InputPhotoUpload.vue";
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: "table-posiciones",
  props: ["polla_id"],
  components: {
    BankAccountModal,
    InputPhotoUpload,
    ImageModal
  },
  watch: {
    text: function (value) {
      this.getFetch();
    },
    polla_id: function (value) {
      if (value) {
        console.log(value)
        this.getFetch();
      }
    },
    positions: function (value) {
      if (value) {
        this.items = _cloneDeep(value);
      }
    }
  },
  data: () => ({
    items: [],
    headers: [
      { text: "Ps", value: "puntos", width: 150, sortable: false },
      { text: "Alias", value: "alias", sortable: false },
      { text: "Usuario", value: "user", sortable: false },
      { text: "DNI", value: "dni", sortable: false },
      { text: "Polla", value: "polla", sortable: false },
      { text: "Estado", value: "estado", sortable: false },
      { text: "Cuentas", value: "cuentas", sortable: false },
      { text: "Pago", value: "pago", sortable: false, width: 150 },
    ],
    page: 1,
    totalPages: 0,
    pageSizes: [10, 20, 30],
    pageSize: 10,
    dialog: false,
    //   loading: false
  }),
  computed: {
    ...mapState({
      positions: (state) => state.polla.positions,
      fetchingData: (state) => state.fetchingData,
    }),
    text() {
      return this.$route.query.text;
    },
  },
  methods: {
    ...mapActions(["getPositionsByPolla", "addUserPollaPayment"]),
    getRequestParams(page, pageSize) {
      let params = {};

      if (this.text) {
        params["search"] = this.text;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["per_page"] = pageSize;
      }
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.getFetch();
      //this.retrieveTutorials();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFetch();
      //this.retrieveTutorials();
    },
    getFetch() {
      const params = this.getRequestParams(this.page, this.pageSize);
      // console.log(this.polla_id)
      this.getPositionsByPolla({ params: params, id: this.polla_id });
    },
    imageUploadEvent(item) {
      this.addUserPollaPayment({
        id: item.id, data: {
          photo_url: item.photo_url,
          photo_name: item.photo_name
        }
      }).then(() => {
        this.getFetch();
      }).catch(error => {
        console.log(error)
      })
    },
    showImage(index) {

    }
  },
  created() {
    // this.getFetch();
  },
};
</script>

<style>
</style>